import React from "react"
import Layout from "../components/layout-simple"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Medical Marketing Arm" />
    <div className="entry-content">
      <h1 className="title-inner">Privacy policy</h1>
      <p>
        This privacy policy has been compiled to better serve those who are
        concerned with how their personal data is being used online. Personal
        data is defined under law, but is generally information that can be used
        on its own or with other information to identify, contact, or locate a
        single person, or to identify an individual in context.
      </p>
      <p>
        Please read our privacy policy carefully to get a clear understanding of
        how we collect, use, protect or otherwise handle your personal data in
        accordance with our website. For the purposes of data protection law,
        Digital Marketing Arm, Inc, DBA Medical Marketing Arm, is the data
        controller and may be contacted via email at{" "}
        <a href="mailto:info@medicalmarketingarm.com">
          info@medicalmarketingarm.com
        </a>
      </p>
      <h3>What personal data do we collect?</h3>
      <p>
        The information collected from users on our website is: name, email,
        phone number, practice area(s), & comments/questions.
      </p>
      <p>
        The information collected from users is used to enable Digital Marketing
        Arm, Inc, DBA Medical Marketing Arm, to respond to any questions,
        concerns, or problems raised by the user, and to send an e-mail
        confirmation to the user.
      </p>
      <h3>How long do we keep your personal data?</h3>
      <p>
        We will only retain your personal data for as long as necessary to
        fulfil the purposes we collected it for, typically to contact you back
        to discuss potential marketing services.
      </p>
      <p>
        To determine the appropriate retention period for personal data, we
        consider the amount, nature, and sensitivity of the personal data, the
        potential risk of harm from unauthorized use or disclosure of your
        personal data, the purposes for which we process your personal data and
        whether we can achieve those purposes through other means, and the
        applicable legal requirements. At any time, however, you may contact us
        to request that we delete all of your personal data.
      </p>
      <h3>When do we collect information?</h3>
      <p>
        We collect information (including personal data) from you when you fill
        out a contact form on our sites, communicate with us over the phone,
        email us, or otherwise enter into a transaction with us.
      </p>
      <h3>Your Access to and Control Over Information</h3>
      <p>
        You may decline to provide us with personal data and/or decline to enter
        into transactions with us.
      </p>
      <p>
        You may also contact us at any time to request: to access your personal
        data, to rectify your personal data, to erase your personal data, or to
        opt-out of future communications from us, at{" "}
        <a href="maito:info@medicalmarketingarm.com">
          info@medicalmarketingarm.com
        </a>
        .
      </p>
      <p>
        We will modify or delete any personal information we hold about you at
        your request. Users have a right to get a copy from us of the personal
        information we hold about them. If you wish to receive a copy of this
        information, please address your request to{" "}
        <a href="maito:info@medicalmarketingarm.com">
          info@medicalmarketingarm.com
        </a>{" "}
        or to the phone number(s) on our website.{" "}
      </p>
      <p>
        You can also express any concern you have about our use of your data. We
        will deal with your request promptly.
      </p>
      <p>
        In addition, you also have the right to lodge a complaint with data
        protection supervisory authorities, the identity of which may depend on
        your location.
      </p>
      <h3>How do we protect and store personal data?</h3>
      <p>
        Our website is scanned on a regular basis with malware for security
        holes and known vulnerabilities in order to make your visit to our
        website as safe as possible.
      </p>
      <p>
        We implement a variety of security measures when a user enters, submits,
        or accesses their personal data to maintain the safety of your personal
        data. Your personal data is contained behind secured networks and is
        only accessible by a limited number of persons who have special access
        rights to such systems, and are required to keep the personal data
        confidential.
      </p>
      <p>
        In addition, all sensitive/credit information you supply is encrypted
        via Secure Socket Layer (SSL) technology.
      </p>
      <p>
        All transactions are processed through a secure gateway provider and
        billing information is never stored or processed on our servers.
      </p>
      <p>
        Unfortunately, transmission of information over the internet is not
        completely secure. We will do our best to protect your personal data,
        but we cannot guarantee its security and any transmission is at your own
        risk. Once we have received your personal data, we will use strict
        procedures and security features to try to prevent unauthorized access.
      </p>
      <h3>Do we use ‘cookies’?</h3>
      <p>
        Yes we use cookies and other similar technologies to distinguish you
        from other users of our websites (including when you browse third party
        websites). This helps us to provide you with a good experience when you
        use our services (for example, to remember your login details and
        improve our services).
      </p>
      <p>
        We also use cookies and similar technologies to show you more
        personalized advertising, including ads for us on other websites. You
        may adjust the settings on your browser to refuse cookies but some of
        our services may not work if you do so. (You can choose to have your
        computer warn you each time a cookie is being sent, or you can choose to
        turn off all cookies.)
      </p>
      <h3>Third-party disclosure</h3>
      <p>
        We we will never share your personal data with a 3rd party, unless in
        the following situations:
      </p>
      <ul>
        <li>
          We may provide your personal data to our business partners, suppliers
          and subcontractors who provide services to us or where necessary to
          perform a service that you have requested (i.e. to process payment
          information that you provide to us).
        </li>
        <li>
          We may provide data about your visit to analytics and search engine
          providers to help us improve and optimize our services. We generally
          only share this information in a form that does not directly identify
          you.
        </li>
        <li>
          We may provide your personal data to our professional advisors (like
          lawyers, accountants, and financial advisors) if needed for our own
          professional advice.
        </li>
        <li>
          We may provide your personal data to law enforcement or regulatory
          authorities if required under law.
        </li>
      </ul>
      <h3>Google Advertising</h3>
      <p>
        Google’s advertising requirements can be summed up by Google’s
        Advertising Principles. They are put in place to provide a positive
        experience for users.{" "}
        <a
          href="https://support.google.com/adwordspolicy/answer/1316548?hl=en"
          target="_blank" rel="noreferrer"
        >
          https://support.google.com/adwordspolicy/answer/1316548?hl=en
        </a>{" "}
        .{" "}
      </p>
      <p>
        Third party vendors, including Google, may show ads on websites on the
        internet.
      </p>
      <h3>California Online Privacy Protection Act</h3>
      <p>
        CalOPPA is the first state law in the nation to require commercial
        websites and online services to post a privacy policy. The law’s reach
        stretches well beyond California to require a person or company in the
        United States (and conceivably the world) that operates websites
        collecting personally identifiable information from California consumers
        to post a conspicuous privacy policy on its website stating exactly the
        information being collected and those individuals with whom it is being
        shared, and to comply with this policy. – See more at:{" "}
        <a
          target="_blank" rel="noreferrer"
          href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf"
        >
          http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
        </a>{" "}
      </p>
      <p>According to CalOPPA we agree to the following:</p>
      <ul>
        <li>Users can visit our website anonymously.</li>
        <li>
          Once this privacy policy is created, we will add a link to it on our
          home page or as a minimum on the first significant page after entering
          our website.
        </li>
        <li>
          Our Privacy Policy link includes the word ‘Privacy’ and can be easily
          be found on the page specified above.
        </li>
        <li>Users will be notified of any privacy policy changes:</li>
      </ul>
      <h3>COPPA (Children Online Privacy Protection Act)</h3>
      <p>
        When it comes to the collection of personal information from children
        under 13, the Children’s Online Privacy Protection Act (COPPA) puts
        parents in control. The Federal Trade Commission, the nation’s consumer
        protection agency, enforces the COPPA Rule, which spells out what
        operators of websites and online services must do to protect children’s
        privacy and safety online. We do not specifically market to children
        under 13.
      </p>
      <h3>Fair Information Practices</h3>
      <p>
        The Fair Information Practices Principles form the backbone of privacy
        law in the United States and the concepts they include have played a
        significant role in the development of data protection laws around the
        globe. Understanding the Fair Information Practice Principles and how
        they should be implemented is critical to comply with the various
        privacy laws that protect personal information.
      </p>
      <p>
        In order to be in line with Fair Information Practices we will take the
        following responsive action, should a data breach occur:
      </p>
      <ul>
        <li>We will notify the users via email within 1 business day</li>
        <li>
          We also agree to the Individual Redress Principle, which requires that
          individuals have a right to pursue legally enforceable rights against
          data collectors and processors who fail to adhere to the law. This
          principle requires not only that individuals have enforceable rights
          against data users, but also that individuals have recourse to courts
          or government agencies to investigate and/or prosecute non-compliance
          by data processors.
        </li>
      </ul>
      <h3>CAN SPAM Act</h3>
      <p>
        The CAN-SPAM Act is a law that sets the rules for commercial email,
        establishes requirements for commercial messages, gives recipients the
        right to have emails stopped from being sent to them, and spells out
        tough penalties for violations.
      </p>
      <p>We may collect your email address in order to:</p>
      <p>
        Send information, respond to inquiries, and/or other requests or
        questions.
      </p>
      <p>
        Market to our mailing list or continue to send emails to our clients
        after the original transaction has occurred.
      </p>
      <p>To be in accordance with CANSPAM we agree to the following:</p>
      <ul>
        <li>NOT use false or misleading subjects or email addresses.</li>
        <li>
          Identify the message as an advertisement in some reasonable way.
        </li>
        <li>
          We will not send mass or bulk emails, where multiple people are
          emailed at the same time.
        </li>
        <li>
          Monitor third-party email marketing services for compliance, if one is
          used.
        </li>
        <li>Honor opt-out/unsubscribe requests quickly.</li>
        <li>
          Allow users to unsubscribe by emailing us and letting us know they do
          not wish to receive further email communication
        </li>
      </ul>
      <p>
        <strong>
          If at any time you would like to unsubscribe from receiving future
          emails, you can email us at{" "}
          <a href="maito:info@medicalmarketingarm.com">
            info@medicalmarketingarm.com
          </a>
        </strong>
      </p>
    </div>
  </Layout>
)

export default IndexPage
