/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import Partners from "../components/general/promo-partners"

import "../assets/css/mma-styles.css"

const Layout = ({ children }) => {
  const layouClass = "mma-theme "

  return (
    <div className={layouClass} id="page">
      <div className="header-holder">
        <div className="header-holder__wrap">
          <Header />
        </div>
      </div>
      <Partners />
      <div className="inner-body">
        <div className="container">
          <div className="inner-body__wrap">
            <div className="inner-body__content">{children}</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
